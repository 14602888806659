const ProviderCourse = {
  id: null,
  provider_id: null,
  course_id: null,
  provider_name: '',
  course_slug: '',
  custom_slug: '',
};

export default ProviderCourse

<template>
  <div class="row">
    <div class="col-12">
      <tabs centered square>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('page.providers') }}</div>
            </div>
          </template>
          <provider-datatable/>
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('common.course_mapping') }}</div>
            </div>
          </template>
          <provider-course-datatable/>
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('common.provider_clients') }}</div>
            </div>
          </template>
          <client-datatable/>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {Tabs, TabPane} from "@/components";
import ProviderDatatable from "@/pages/Registries/datatables/ProviderDatatable";
import ProviderCourseDatatable from "@/pages/Registries/datatables/ProviderCourseDatatable";
import ClientDatatable from "@/pages/Registries/datatables/ClientDatatable";

export default {
  name: "ProvidersPage",
  components: {
    ClientDatatable,
    ProviderCourseDatatable,
    ProviderDatatable,
    Tabs,
    TabPane
  }
}
</script>

<style scoped>

</style>

<template>
  <ValidationObserver ref="clientForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.active')" :label-width="labelWidth" v-if="clientLocal.id">
        <template slot="value">
          <el-switch v-model="enabled"/>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('page.provider')" :label-width="140">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              v-model="clientLocal.user_id"
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
            >
              <el-option
                v-for="provider in providers"
                class="select-default text-uppercase"
                :key="provider.id"
                :value="provider.id"
                :label="provider.name"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.name')" :label-width="labelWidth" v-if="clientLocal.id">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="name"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              type="text"
              maxlength="50"
              v-model="clientLocal.name"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.secret')" :label-width="labelWidth" v-if="clientLocal.id">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="secret"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              maxlength="50"
              v-model="clientLocal.secret"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import Client from "@/models/client";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Select, Option, Switch} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "ClientForm",
  components: {ListGroupItemComponent, [Select.name]: Select, [Option.name]: Option, [Switch.name]: Switch},
  props: {
    client: {
      type: Object,
      default: () => this.$_.cloneDeep(Client)
    }
  },
  data() {
    return {
      clientLocal: this.$_.cloneDeep(Client),
      enabled: false,
      labelWidth: 140
    }
  },
  mounted() {
    this.clientLocal = this.$_.cloneDeep(this.client);
    this.enabled = !this.clientLocal.revoked;
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers',
    }),
  },
  methods: {
    getClientData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.clientForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              this.clientLocal.revoked = !this.enabled;
              resolve(this.clientLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>

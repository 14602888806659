<template>
  <ValidationObserver ref="providerForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.active')" :label-width="labelWidth">
        <template slot="value">
          <el-switch v-model="providerLocal.enabled"/>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.provider_name')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="provider_name"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              type="text"
              maxlength="50"
              v-model="providerLocal.name"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.provider_slug')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="provider_slug"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              maxlength="50"
              v-model="providerLocal.slug"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.acronym')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="acronym"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              type="text"
              maxlength="50"
              v-model="providerLocal.acronym"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.provider_price')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="provider_price"
            rules="required|between:0,2000"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              type="text"
              maxlength="8"
              v-model="providerLocal.record_price"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.campaign_default')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="flex-grow-1 mb-0">
            <el-select
              clearable
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="providerLocal.campaign_id"
            >

              <el-option
                v-for="option in campaignsOptions"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>

        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import Provider from "@/models/provider";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Option, Select, Switch} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "ProviderForm",
  components: {
    ListGroupItemComponent,
    [Switch.name]: Switch,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    provider: {
      type: Object,
      default: () => this.$_.cloneDeep(Provider)
    }
  },
  data() {
    return {
      providerLocal: this.$_.cloneDeep(Provider),
      labelWidth: 160
    }
  },
  mounted() {
    this.providerLocal = this.$_.cloneDeep(this.provider);
  },
  computed: {
    ...mapGetters({
      campaigns: 'common/campaigns',
    }),

    campaignsOptions() {
      return this.$_.map(
        this.campaigns,
        campaign => { return {label: campaign.name, value: campaign.id}
        });
    },
  },
  methods: {
    getProviderData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.providerForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve({
                id: this.providerLocal.id,
                enabled: this.providerLocal.enabled,
                name: this.providerLocal.name,
                campaign_id: this.providerLocal.campaign_id,
                slug: this.providerLocal.slug,
                record_price: this.providerLocal.record_price,
                acronym: this.providerLocal.acronym,
              });
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <octo-table
      show-filters
      enable-add
      :enable-store="false"
      :action="endpoints.DATATABLES.providers"
      :fields="fields"
      :filters="filters"
      :key="providerKey"
      name="ProviderDataTable"
      @onResetFilters="resetFilters"
      @onAdd="openModal"
    >
      <template v-slot:name="data">
        <div class="text-uppercase text-truncate small">
          {{ data.row.name }}
        </div>
      </template>

      <template v-slot:slug="data">
        <div class="small">
          {{ data.row.slug }}
        </div>
      </template>

      <template v-slot:acronym="data">
        <div class="small">
          {{ data.row.acronym }}
        </div>
      </template>

      <template v-slot:campaign="data">
        <div class="small" v-if="data.row.campaign_id">
          {{ campaigns[data.row.campaign_id] | optional('name')  }}
        </div>
      </template>

      <template v-slot:enabled="data">
        <div v-if="data.row.enabled" class="small">SI</div>
        <div v-if="!data.row.enabled" class="small">NO</div>
      </template>

      <template v-slot:record_price="data">
        <div class="text-uppercase text-truncate small">
          {{ data.row.record_price | euro }}
        </div>
      </template>

      <template slot="custom-filters">
        <div class="col-12 col-md-6">
          <base-input :label="$t('datatable.active')">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="filters.enabled"
            >
              <el-option
                v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              />
            </el-select>
          </base-input>
        </div>
      </template>
      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip :content="$t('common.info')"
                      :open-delay="300"
                      placement="top">
            <base-button
              class="mx-1"
              size="sm"
              icon
              link
              @click="openModal(data.row)"
            >
              <octo-icon icon="right-arrow"/>
            </base-button>
          </el-tooltip>
        </div>
      </template>
    </octo-table>
    <modal centered :show.sync="showModal">
      <template slot="header">
        <h5 class="modal-title">{{ provider.name || $t('common.new_provider') }}</h5>
      </template>
      <provider-form ref="providerForm" :provider="provider" :key="`form-${providerFormKey}`"/>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="saveProvider">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import Provider from "@/models/provider";
import ProviderForm from "@/pages/Registries/forms/ProviderForm";
import {Select, Option} from "element-ui";
import {mapGetters} from "vuex";
import campaign from "../../../models/campaign";

export default {
  name: "ProviderDatatable",
  components: {ProviderForm, Modal, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      showModal: false,
      endpoints: endpoints,
      filters: {},
      providerKey: 1,
      providerId: 1,
      provider: this.$_.cloneDeep(Provider),
      providerFormKey: 1,
      fields: [
        {prop: 'name', label: 'name', minWidth: 200, slot: true, fixed: true},
        {prop: 'enabled', label: 'active', slot: true, align: 'center'},
        {prop: 'campaign', label: 'campaign', slot: true, align: 'left', width: 250},
        {prop: 'slug', label: 'slug', slot: true, align: 'center', width: 150},
        {prop: 'acronym', label: 'acronym', slot: true, align: 'center', width: 150},
        {prop: 'record_price', label: 'cost', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', slot: true, fixed: 'right'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      campaigns: 'common/campaigns',
    }),
  },
  methods: {
    resetFilters: function () {
      this.filters = {};
    },

    openModal: function (provider) {
      this.provider = this.$_.isEmpty(provider) ? this.$_.cloneDeep(Provider) : this.$_.cloneDeep(provider);
      this.providerFormKey++
      this.showModal = true;
    },

    saveProvider: async function () {
      try {
        let providerData = await this.$refs.providerForm.getProviderData();

        try {
          let result = await this.$api({
            method: providerData.id ? 'put' : 'post',
            url: providerData.id
              ? endpoints.PROVIDER_UPDATE.replace('{id}', providerData.id)
              : endpoints.PROVIDER_STORE,
            data: providerData
          });

          this.providerId = result.data.data.id;
          this.provider = result.data.data;
          this.providerKey++;

          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.showModal = false;
        } catch (e) {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        }

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_wrong')});
      }
    },
  }

}
</script>

<style scoped>

</style>

<template>
  <ValidationObserver ref="providerCourseForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('page.provider')" :label-width="140">
        <template slot="value">
          <label-theme-component v-if="providerCourseLocal.id">
            {{ providerCourseLocal.provider_name }}
          </label-theme-component>
          <base-input class="mb-0 flex-grow-1">
            <el-select
              v-model="providerCourseLocal.provider_id"
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
            >
              <el-option
                v-for="provider in providers"
                class="select-default text-uppercase"
                :key="provider.id"
                :value="provider.id"
                :label="provider.name"
              />
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('common.course')" :label-width="140">
        <template slot="value">
          <label-theme-component v-if="providerCourseLocal.id">
            {{ courses[providerCourseLocal.course_id] | optional('title') }}
          </label-theme-component>
          <base-input class="mb-0 flex-grow-1" v-else>
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="providerCourseLocal.course_id"
            >
              <el-option
                v-for="course in courses"
                class="select-default text-uppercase"
                :value="course.id"
                :label="course.code + ': ' + course.title"
                :key="course.id"
              />
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.custom_slug')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="custom_slug"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              maxlength="50"
              v-model="providerCourseLocal.custom_slug"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Select, Option} from "element-ui";
import {mapGetters} from "vuex";
import ProviderCourse from "@/models/providerCourse";
import LabelThemeComponent from "../../../components/LabelThemeComponent";

export default {
  name: "ProviderCourseForm",
  components: {LabelThemeComponent, ListGroupItemComponent, [Select.name]: Select, [Option.name]: Option},
  props: {
    providerCourse: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderCourse)
    }
  },
  data() {
    return {
      providerCourseLocal: this.$_.cloneDeep(ProviderCourse),
      labelWidth: 140,
    }
  },
  mounted() {
    this.providerCourseLocal = this.$_.cloneDeep(this.providerCourse);
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers',
      courses: 'common/allCourses',
    }),
  },
  methods: {
    getProviderCourseData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.providerCourseForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.providerCourseLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>

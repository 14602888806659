var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"clientForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[(_vm.clientLocal.id)?_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.active'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('el-switch',{model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1)],2):_vm._e(),_c('list-group-item-component',{attrs:{"label":_vm.$t('page.provider'),"label-width":140}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.choose')},model:{value:(_vm.clientLocal.user_id),callback:function ($$v) {_vm.$set(_vm.clientLocal, "user_id", $$v)},expression:"clientLocal.user_id"}},_vm._l((_vm.providers),function(provider){return _c('el-option',{key:provider.id,staticClass:"select-default text-uppercase",attrs:{"value":provider.id,"label":provider.name}})}),1)],1)],1)],2),(_vm.clientLocal.id)?_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.name'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","maxlength":"50","error":errors[0]},model:{value:(_vm.clientLocal.name),callback:function ($$v) {_vm.$set(_vm.clientLocal, "name", $$v)},expression:"clientLocal.name"}})]}}],null,false,2703042639)})],1)],2):_vm._e(),(_vm.clientLocal.id)?_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.secret'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"secret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","maxlength":"50","error":errors[0]},model:{value:(_vm.clientLocal.secret),callback:function ($$v) {_vm.$set(_vm.clientLocal, "secret", $$v)},expression:"clientLocal.secret"}})]}}],null,false,172744621)})],1)],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
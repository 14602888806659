var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"providerForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.active'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('el-switch',{model:{value:(_vm.providerLocal.enabled),callback:function ($$v) {_vm.$set(_vm.providerLocal, "enabled", $$v)},expression:"providerLocal.enabled"}})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.provider_name'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"provider_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","maxlength":"50","error":errors[0]},model:{value:(_vm.providerLocal.name),callback:function ($$v) {_vm.$set(_vm.providerLocal, "name", $$v)},expression:"providerLocal.name"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.provider_slug'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"provider_slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","maxlength":"50","error":errors[0]},model:{value:(_vm.providerLocal.slug),callback:function ($$v) {_vm.$set(_vm.providerLocal, "slug", $$v)},expression:"providerLocal.slug"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.acronym'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"acronym","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","maxlength":"50","error":errors[0]},model:{value:(_vm.providerLocal.acronym),callback:function ($$v) {_vm.$set(_vm.providerLocal, "acronym", $$v)},expression:"providerLocal.acronym"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.provider_price'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"provider_price","rules":"required|between:0,2000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","maxlength":"8","error":errors[0]},model:{value:(_vm.providerLocal.record_price),callback:function ($$v) {_vm.$set(_vm.providerLocal, "record_price", $$v)},expression:"providerLocal.record_price"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.campaign_default'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"flex-grow-1 mb-0"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"clearable":"","placeholder":_vm.$t('fields.choose')},model:{value:(_vm.providerLocal.campaign_id),callback:function ($$v) {_vm.$set(_vm.providerLocal, "campaign_id", $$v)},expression:"providerLocal.campaign_id"}},_vm._l((_vm.campaignsOptions),function(option){return _c('el-option',{key:option.value,staticClass:"select-default text-uppercase",attrs:{"value":option.value,"label":option.label}})}),1)],1)],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <octo-table
      enable-add
      show-filters
      :enable-store="false"
      :show-id="false"
      :action="endpoints.DATATABLES.clients"
      :fields="fields"
      :filters="filters"
      name="ClientDataTable"
      @onResetFilters="resetFilters"
      @onAdd="addClient"
      ref="clientDatatable"
    >
      <template v-slot:secret="data">
        <div class="small">
          <b class="text-uppercase">{{ $t('fields.client_id') }}:</b> {{ data.row | optional('id') }}
        </div>
        <div class="small">
          <b class="text-uppercase">{{ $t('fields.secret') }}:</b> {{ data.row | optional('secret') }}
        </div>
      </template>

      <template v-slot:provider="data">
        <div class="text-uppercase text-truncate small">
          {{ getProvider(data.row.user_id) }}
        </div>
      </template>

      <template v-slot:endpoint="data">
        <div class="text-truncate small">
          {{ data.row.endpoint }}
        </div>
      </template>

      <template v-slot:enabled="data">
        <octo-icon :icon="data.row.revoked ? 'wrong' : 'checked'"/>
      </template>

      <template slot="custom-filters">
        <div class="col-12">
          <base-input :label="$t('page.providers')">
            <el-select
              multiple
              filterable
              clearable
              class="select-default text-uppercase"
              v-model="filters.providerIds"
            >
              <el-option
                v-for="option in providers"
                class="select-default text-uppercase w-100"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </template>
      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip
            :content="data.row.revoked ? $t('common.enable') : $t('common.disable')"
            :open-delay="200"
            placement="right"
          >
            <base-button
              icon
              link
              confirm-required
              class="mx-1"
              size="sm"
              :confirm-text="data.row.revoked ? $t('confirm.enable_client') : $t('confirm.disable_client')"
              @click="toggleRevokedValue(data.row)"
            >
              <octo-icon :icon="data.row.revoked ? 'check' : 'cancel' "/>
            </base-button>
          </el-tooltip>
          <base-button
            icon
            link
            confirm-required
            class="mx-1"
            size="sm"
            :confirm-text="$t('confirm.refresh_client_secret')"
            @click="refreshSecret(data.row)"
          >
            <octo-icon icon="padlock"/>
          </base-button>
        </div>
      </template>
    </octo-table>
    <modal bodyClasses="p-2" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.add') }}</h5>
      <client-form ref="clientForm" :client="selectedClient" :key="modalKey"/>
      <template slot="footer">
        <base-button class="text-uppercase" link @click="storeClient">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";
import {Select, Option} from "element-ui";
import ClientForm from "@/pages/Registries/forms/ClientForm";
import Client from "@/models/client";

export default {
  name: "ClientDatatable",
  components: {ClientForm, Modal, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},

  data() {
    return {
      selectedClient: this.$_.cloneDeep(Client),
      showModal: false,
      modalKey: 0,
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'secret', label: 'credentials', width: 400, slot: true, fixed: true},
        {prop: 'provider', label: 'provider', width: 300, slot: true},
        {prop: 'endpoint', label: 'endpoint', minWidth: 300, slot: true},
        {prop: 'enabled', label: 'enabled', slot: true, align: 'center', width: 100},
        {prop: 'actions', label: 'actions', slot: true, width: 100, fixed: 'right'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers',
    }),
  },
  methods: {
    resetFilters: function () {
      this.filters = {};
    },

    getProvider(providerId) {
      return this.$_.find(this.providers, provider => {
        return providerId === provider.id
      })?.name;
    },

    addClient() {
      this.selectedClient = this.$_.cloneDeep(Client);
      this.modalKey++;
      this.showModal = true;
    },

    async storeClient() {
      try {
        this.$fullLoading.show();

        const data = await this.$refs.clientForm.getClientData();

        await this.$api.post(endpoints.CLIENT_STORE_FROM_PROVIDER.replace('{providerId}', data.user_id));

        this.showModal = false;
        this.$refs.clientDatatable.getData();

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')})
      } finally {
        this.$fullLoading.hide();
      }
    },

    toggleRevokedValue(client) {
      this.$fullLoading.show();
      this.$api.put(endpoints.CLIENT_TOGGLE_REVOKED_VALUE.replace('{id}', client.id))
        .then(() => {
          this.$refs.clientDatatable.getData();
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')})
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')})
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    refreshSecret(client) {
      this.$fullLoading.show();
      this.$api.put(endpoints.CLIENT_REFRESH_SECRET.replace('{id}', client.id))
        .then(() => {
          this.$refs.clientDatatable.getData();
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')})
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')})
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }
  }
}
</script>

<style scoped>

</style>

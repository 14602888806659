<template>
  <div>
    <octo-table
      show-filters
      enable-add
      :enable-store="false"
      :show-id="false"
      :action="endpoints.DATATABLES.providerCourse"
      :fields="fields"
      :filters="filters"
      name="ProviderCourseDataTable"
      ref="providerCourseDatatable"
      @onResetFilters="resetFilters"
      @onAdd="openModal({})"
    >
      <template v-slot:provider="data">
        <div class="text-uppercase text-truncate small">{{ data.row.provider_name }}</div>
      </template>

      <template v-slot:course="data">
        <div class="text-uppercase text-truncate small">{{ data.row.course_slug }}</div>
      </template>

      <template v-slot:custom_slug="data">
        <div class="text-uppercase text-truncate small">{{ data.row.custom_slug }}</div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <base-button class="mx-1" size="sm" icon link @click="openModal(data.row)">
            <octo-icon icon="edit"/>
          </base-button>
          <base-button class="mx-1" size="sm" icon link @click="detachProviderCustomCourseSlug(data.row)">
            <octo-icon icon="wrong"/>
          </base-button>
        </div>
      </template>

      <template slot="custom-filters">
        <div class="col-12 col-md-4">
          <base-input :label="$t('page.providers')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.providerIds"
            >
              <el-option
                v-for="provider in providers"
                class="select-default text-uppercase"
                :key="provider.id"
                :value="provider.id"
                :label="provider.name"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-8">
          <base-input :label="$t('datatable.courses')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.courseIds"
            >
              <el-option
                v-for="course in courses"
                class="select-default text-uppercase"
                :value="course.id"
                :label="course.code + ': ' + course.title"
                :key="course.id"
              />
            </el-select>
          </base-input>
        </div>
      </template>
    </octo-table>
    <modal bodyClasses="p-2" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ selectedProviderCourse.id ? $t('common.update') : $t('common.add') }}
      </h5>
      <provider-course-form ref="providerCourseForm" :provider-course="selectedProviderCourse" :key="modalKey"/>
      <template slot="footer">
        <base-button class="text-uppercase" link @click="syncProviderCourse">
          {{ $t('common.save') }}
        </base-button>
        <base-button class="text-uppercase" link @click="detachProviderCustomCourseSlug(selectedProviderCourse)">
          {{ $t('common.delete') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";
import {Select, Option} from "element-ui";
import ProviderCourseForm from "../forms/ProviderCourseForm";
import ProviderCourse from "@/models/providerCourse";

export default {
  name: "ProviderCourseDatatable",
  components: {ProviderCourseForm, Modal, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},

  data() {
    return {
      showModal: false,
      modalKey: 0,
      selectedProviderCourse: this.$_.cloneDeep(ProviderCourse),
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'provider', label: 'provider', minWidth: 200, slot: true},
        {prop: 'course', label: 'course', slot: true, minWidth: 200, align: 'center'},
        {prop: 'custom_slug', label: 'custom_slug', slot: true, minWidth: 200},
        {prop: 'actions', label: 'actions', slot: true, fixed: 'right'},
      ]
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {};
    },

    openModal(selectedProviderCourse) {
      this.selectedProviderCourse = !this.$_.isEmpty(selectedProviderCourse)
        ? this.$_.cloneDeep(selectedProviderCourse)
        : this.$_.cloneDeep(ProviderCourse);
      this.modalKey++;
      this.showModal = true;
    },

    async syncProviderCourse() {
      try {
        this.$fullLoading.show();

        const data = await this.$refs.providerCourseForm.getProviderCourseData();

        await this.$api.put(
          endpoints.PROVIDER_SYNC_COURSE_CUSTOM_SLUG
            .replace('{id}', data.provider_id)
            .replace('{courseId}', data.course_id),
          {slug: data.custom_slug}
        );

        this.showModal = false;
        await this.$refs.providerCourseDatatable.getData();
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')})
      } finally {
        this.$fullLoading.hide();
      }
    },

    async detachProviderCustomCourseSlug(providerCourse) {
      try {
        this.$fullLoading.show();

        await this.$api.put(
          endpoints.PROVIDER_DETACH_COURSE_CUSTOM_SLUG
            .replace('{id}', providerCourse.provider_id)
            .replace('{courseId}', providerCourse.course_id)
        );

        this.showModal = false;
        await this.$refs.providerCourseDatatable.getData();
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')})
      } finally {
        this.$fullLoading.hide();
      }
    }
  },
  computed: {
    ...mapGetters({
      providers: "common/providers",
      courses: "common/allCourses",
    })
  }

}
</script>

<style scoped>

</style>
